import { Box, Grid, Typography } from '@mui/material';

function HowToBuy(props) {
  const { howToBuy } = props;
  return (
    <Box sx={{
      height: { sm: 'auto', md: '100vh' },
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      m: 16,
      mx: {xs: 2, sm: 16}
    }}>
      <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Typography
          fontWeight={800}
          sx={{
            fontSize: { xs: '40px', md: '50px' },
            textAlign: 'center', mb: 4
          }}>
          HOW TO BUY?
        </Typography>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {howToBuy.map((howTo) => (
            <Grid item xs={12} md={howTo.xs} sx={{ mt: 4 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: howTo.maxWidth || 400 }}>
                <Typography fontWeight={600}>{howTo.title}</Typography>
                <Typography color={'rgba(255, 255, 255, 0.82)'}>{howTo.text}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default HowToBuy;
