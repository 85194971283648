import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import InterRegular from './assets/fonts/Inter-Regular.ttf';
import InterExtraBold from './assets/fonts/Inter-ExtraBold.ttf';
import InterSemiBold from './assets/fonts/Inter-SemiBold.ttf';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: 'Inter'
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: 'black'
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: [
          {
            '@font-face': {
              fontFamily: 'Inter',
              src: `url(${InterRegular}) format("truetype")`,
              fontWeight: 'normal'
            },
          },
          {
            '@font-face': {
              fontFamily: 'Inter',
              src: `url(${InterExtraBold}) format("truetype")`,
              fontWeight: 800
            },
          },
          {
            '@font-face': {
              fontFamily: 'Inter',
              src: `url(${InterSemiBold}) format("truetype")`,
              fontWeight: 600
            },
          }
        ],

      }
    },
    MuiTypography: {
      defaultProps: {
        color: 'white'
      },
    },
  },
});

export default theme;
