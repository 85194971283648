import { SvgIcon } from '@mui/material';

function TelegramIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50, cursor: 'pointer' }} viewBox={'0 0 48 47'} {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.7515 45.9268C35.7646 45.9268 45.5031 36.3173 45.5031 24.4634C45.5031 12.6095 35.7646 3 23.7515 3C11.7385 3 2 12.6095 2 24.4634C2 36.3173 11.7385 45.9268 23.7515 45.9268Z" fill="black" stroke="url(#paint0_linear_118_67758)" stroke-width="3" stroke-miterlimit="10"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0518 26.8992L21.0516 26.899L21.0519 26.8988L21.0519 26.8987L21.052 26.8987L28.7271 20.0171C29.064 19.7201 28.6536 19.5752 28.2064 19.8446L18.7337 25.7823L14.6421 24.5135C13.7584 24.2447 13.7521 23.6414 14.8404 23.2077L30.7848 17.0992C31.513 16.7708 32.2159 17.273 31.9379 18.3804L29.2225 31.0935C29.0329 31.9969 28.4835 32.213 27.7223 31.7957L23.5861 28.7595L21.5978 30.6803C21.5916 30.6863 21.5853 30.6924 21.5791 30.6984C21.3568 30.9135 21.1728 31.0914 20.7702 31.0914L21.0518 26.8992Z" fill="url(#paint1_linear_118_67758)"/>
        <defs>
          <linearGradient id="paint0_linear_118_67758" x1="7.07514" y1="40.5609" x2="43.3719" y2="39.5588" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8595EA"/>
            <stop offset="1" stop-color="#D085EA"/>
          </linearGradient>
          <linearGradient id="paint1_linear_118_67758" x1="16.0999" y1="30.125" x2="31.1136" y2="29.6342" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8595EA"/>
            <stop offset="1" stop-color="#D085EA"/>
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default TelegramIcon;
