import { Box, Grid } from '@mui/material';

function SocialMediaBlock(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {props.socialMedias.map((sm) => (
          <Grid item>
            {sm.icon}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SocialMediaBlock;
