import { Box, Typography } from '@mui/material';
import nftDesktop from '../../assets/images/nft-desktop.png';
import nftMobile from '../../assets/images/nft-mobile.png';
function NFT() {
  return (
    <Box sx={{
      height: { sm: 'auto', md: '100vh' },
      display: 'flex',
      flexDirection: 'column',
      m: 16,
      mx: { xs: 2, sm: 16 }
    }}>
      <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Typography
          fontWeight={800}
          sx={{
            // background: 'linear-gradient(88.44deg, #8595EA 11.69%, #D085EA 92.97%)',
            // backgroundClip: 'text',
            // textFillColor: 'transparent',
            fontSize: { xs: '30px', md: '140px' },
            textAlign: 'center', mb: 4
          }}>
          NFT
        </Typography>
        <Box sx={{
          display: { xs: 'none', md: 'flex' }
        }}>
          <img
            src={nftDesktop}
            alt={'nft-desktop'}
            style={{ width: '100%' }}
          />
        </Box>
        <Box sx={{
          display: { xs: 'flex', md: 'none' }
        }}>
          <img
            src={nftMobile}
            alt={'nft-desktop'}
            style={{ width: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default NFT;
