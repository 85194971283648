import * as React from 'react';
import Button from '@mui/material/Button';

function StyledButton (props) {
  const { text, onClick, display, margin } = props;
  return (
    <Button
      className={'button'}
      variant={'outlined'}
      size={'large'}
      onClick={onClick}
      sx={{
        color: '#8595EAFF',
        backgroundColor: 'black',
        fontWeight: 800,
        boxShadow: 'inset 0px 0px 0px 1px #8595EA',
        borderRadius: '6px',
        width: 250,
        display,
        margin,
        ':hover': {
          background: 'linear-gradient(88.44deg, #8595EA 11.69%, #D085EA 92.97%);', // theme.palette.primary.main
          color: 'white',
        },
      }}
    >
      {text}
    </Button>
  )
}

export default StyledButton;
