import { Box, Typography } from '@mui/material';
import SpaceXPEPEIcon from '../../assets/svg/SpaceXPEPEIcon.js';
import SocialMediaBlock from '../SocialMediaBlock/SocialMediaBlock.js';

function Footer(props) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      p: 2,

    }}>
      <SpaceXPEPEIcon sx={{ mt: 6, width: 200, height: 150 }}/>
      <Box sx={{
        display: 'flex'
      }}>
        <Typography
          fontWeight={600} sx={{ my: 3, mr: 0.5, fontSize: { xs: '30px', sm: '60px' } }}>SpaceX</Typography>
        <Typography color={'#8595EA'} fontWeight={600}
                    sx={{ my: 3, ml: 0.5, fontSize: { xs: '30px', sm: '60px' } }}>PEPE</Typography>
      </Box>
      <SocialMediaBlock socialMedias={props.socialMedias}/>
      <Box sx={{
        mt: 3
      }}>
        <Typography color={'rgba(255, 255, 255, 0.5)'}>Copyright: © {new Date().getFullYear()}
          <b style={{ marginLeft: 4 }}>SPXPEPE</b></Typography>
      </Box>
    </Box>
  );
}

export default Footer;
