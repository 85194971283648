import { Box, Grid, Typography } from '@mui/material';
import tokenomicsGraph from '../../assets/images/tokenomic-graph.png';
import tokenomicsStats from '../../assets/images/tokenonics-values.png';

function Tokenomics(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        m: { xs: 0, md: 16 }
      }}>
      <Box sx={{
        textAlign: 'center',
        m: 2
      }}>
        <Typography
          sx={{
            fontSize: { xs: '30px', md: '140px' },
            background: 'linear-gradient(88.44deg, #8595EA 11.69%, #D085EA 92.97%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
          }}
          fontWeight={800}
        >
          TOKENOMICS
        </Typography>
        <Typography>Read whitepaper →</Typography>
      </Box>
      <Box sx={{ m: 2, textAlign: 'center' }}>
        <Typography sx={{ m: 1 }} variant={'body1'}>Initial supply: 420,690,000,000 SPXPEPE</Typography>
        <Typography variant={'h5'} fontWeight={600}>There are <b>NO TAXES</b> in Space!</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          maxWidth: { xs: '100%', sm: '90%' },
          m: 2,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={12} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
              <img
                src={tokenomicsGraph}
                alt={'tokenomics'}
                style={{
                  width: '100%',
                  margin: 'auto',
                  maxWidth: 500
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: '100%', display: 'flex' }}>
              <img
                src={tokenomicsStats}
                alt={'tokenomics-stats'}
                style={{
                  width: '100%',
                  margin: 'auto',
                  maxWidth: 500
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ m: 2, textAlign: 'center' }}>
        <Typography fontWeight={600}>CONTRACT ADDRESS</Typography>
        <Typography
          sx={{ fontSize: { xs: '10px', md: '20px' } }}
          fontWeight={800}>{props.ca}</Typography>
      </Box>
    </Box>
  );
}

export default Tokenomics;
