import { Box, Typography } from '@mui/material';
import SpaceXPEPEIcon from '../../assets/svg/SpaceXPEPEIcon.js';
import SocialMediaBlock from '../../components/SocialMediaBlock/SocialMediaBlock.js';
import StyledButton from '../../components/SyledButton/StyledButton.js';
import * as React from 'react';

function Home(props) {
  return (<Box
    sx={{
      height: { sm: 'auto', md: '100vh' },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mb: {
        xs: 4, sm: 0
      },
      mx: { xs: 2, md: 8 },

    }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mt: { xs: 10, sm: 10, md: 0 } }}>
      <StyledButton
        text={'Buy Now'}
        display={{ xs: 'flex', md: 'none' }}
        margin={'auto'}
      />
      <Typography
        fontWeight={800} lineHeight={{ xs: 2, sm: '155px' }}
        sx={{ fontSize: { xs: '60px', sm: '128px' } }}
      >
        SpaceX
      </Typography>
      <Typography
        fontWeight={800} color={'#8595EA'} lineHeight={{ xs: 0, sm: '155px' }}
        sx={{ fontSize: { xs: '60px', sm: '128px' } }}
      >
        PEPE
      </Typography>
    </Box>
    <Box>
      <SpaceXPEPEIcon sx={{ mt: { xs: 8, sm: 8 }, width: 250, height: 200 }}/>
    </Box>
    <Box sx={{ maxWidth: 600, textAlign: 'center', mt: 0 }}>
      <Box sx={{ my: 2 }}>
        <SocialMediaBlock socialMedias={props.socialMedias}/>
      </Box>
      <Typography variant={'h5'} fontWeight={600} sx={{ mt: { xs: 6, sm: 0 } }}>
        SpaceX PEPE represents the aspirations and dreams of those who dare to reach for the stars.
      </Typography>
    </Box>
  </Box>);
}

export default Home;
