import './App.css';
import { useRef } from 'react';

import Navbar from './components/Navbar/Navbar.js';
import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import Spacemap from './pages/Spacemap/Spacemap.js';
import Partners from './pages/Partners/Partners.js';
import HowToBuy from './pages/HowToBuy/HowToBuy.js';
import Tokenomics from './pages/Tokenomics/Tokenomics.js';

import { spacemap, partners, howToBuy, socialMedias } from './constants';
import Footer from './components/Footer/Footer.js';
import bg from './assets/images/background.png';
import NFT from './pages/NFT/NFT.js';

const ca = '0xA854486E357F479F81133d83F21AbDA7B80CB082';

function App() {
  const tokenomicsRef = useRef(null);
  const spaceMapRef = useRef(null);
  const aboutRef = useRef(null);
  const nftRef = useRef(null);
  const homeRef = useRef(null);
  const pages = [
    {
      name: 'Home',
      onClick: () => {
        homeRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    },
    {
      name: 'About',
      onClick: () => {
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    },
    {
      name: 'NFT',
      onClick: () => {
        nftRef.current?.scrollIntoView({ behavior: 'smooth', block: "start", });
      },
    },
    {
      name: 'SpaceMap',
      onClick: () => {
        spaceMapRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    },
    {
      name: 'Tokenomics',
      onClick: () => {
        tokenomicsRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    },
  ];

  return (
    <div className="App" style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top'
    }}>
      <Navbar pages={pages}/>
      <div ref={homeRef}>
        <Home socialMedias={socialMedias}/>
      </div>
      <div ref={aboutRef}>
        <About/>
      </div>
      <div ref={nftRef}>
        <NFT/>
      </div>
      <div ref={spaceMapRef}>
        <Spacemap spacemap={spacemap}/>
      </div>
      <div ref={tokenomicsRef}>
        <Tokenomics ca={ca}/>
      </div>
      <Partners partners={partners}/>
      <HowToBuy howToBuy={howToBuy}/>
      <Footer socialMedias={socialMedias}/>
    </div>
  );
}

export default App;
