import { Box, Typography } from '@mui/material';
import elonMuskImage from '../../assets/images/ElonMuskDark.png';

function RoadmapCard(props) {
  const { title, image, values, style } = props;
  return (
    <Box sx={{
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '20px',
      borderImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.68%, rgba(0, 0, 0, 0.9) 70.22%, rgba(0, 0, 0, 0) 98.28%);',
      background: '#171717',
      opacity: 0.95,
      // minWidth: style === 'horizontal' ? 800 : 400,
      // minWidth: style === 'square' ? 'auto' : 392,
      minHeight: { xs: style === 'vertical' ? 'auto' : 330, lg: style === 'vertical' ? 700 : 324 },
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minWidth: 300,
    }}>
      {/*{style === 'vertical' &&*/}
      {/*  <SpaceXPEPEIcon*/}
      {/*    sx={{ position: 'absolute', width: 140, height: 180, right: 20, top: -120 }}*/}
      {/*  />*/}
      {/*}*/}
      <Box sx={{ p: 4, pb: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          {image}
          <Typography sx={{ m: 'auto 0', ml: 2 }} variant={'h6'} fontWeight={800} color={'#666D98'}>{title}</Typography>
        </Box>
        <Box sx={{ mt: 2, display: 'flex' }}>
          <Box>
            {values.map(text => (
              <Typography
                sx={{ my: 2 }}
                variant={'body1'}
                fontWeight={600}>
                {text}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      {style === 'horizontal' &&
        <Box sx={{ m: 'auto 0', mr: 4, display: { xs: 'none', md: 'flex' } }}>
          <img src={elonMuskImage} alt={'elon-musk-face'}/>
        </Box>
      }
    </Box>
  );
}

export default RoadmapCard;
