import { SvgIcon } from '@mui/material';

function TwitterIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50, cursor: 'pointer' }} viewBox={'0 0 48 47'} {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.7515 45.9268C35.7646 45.9268 45.5031 36.3173 45.5031 24.4634C45.5031 12.6095 35.7646 3 23.7515 3C11.7385 3 2 12.6095 2 24.4634C2 36.3173 11.7385 45.9268 23.7515 45.9268Z" fill="black" stroke="url(#paint0_linear_118_67749)" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M14.7146 30.0625C16.3538 31.0994 18.3013 31.7077 20.3959 31.7077C27.2821 31.7077 31.1701 25.9703 30.9389 20.8205C31.6605 20.3021 32.291 19.6592 32.7883 18.9265C32.1228 19.2168 31.4083 19.4173 30.6587 19.5002C31.4223 19.0509 32.0107 18.332 32.291 17.4748C31.5764 17.8965 30.7778 18.2006 29.9372 18.3665C29.2577 17.6546 28.2979 17.2122 27.2331 17.2122C24.8373 17.2122 23.079 19.4173 23.6184 21.7053C20.536 21.5532 17.8039 20.0947 15.9756 17.8827C15.0018 19.5279 15.4712 21.6776 17.1244 22.7698C16.515 22.7491 15.9405 22.5832 15.4432 22.3136C15.4011 24.0072 16.6341 25.597 18.4204 25.9496C17.895 26.0878 17.3276 26.1224 16.7461 26.0118C17.2155 27.4634 18.5885 28.521 20.2068 28.5556C18.6516 29.7584 16.6831 30.2976 14.7146 30.0694V30.0625Z" fill="url(#paint1_linear_118_67749)"/>
        <defs>
          <linearGradient id="paint0_linear_118_67749" x1="7.07514" y1="40.5609" x2="43.3719" y2="39.5588" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8595EA"/>
            <stop offset="1" stop-color="#D085EA"/>
          </linearGradient>
          <linearGradient id="paint1_linear_118_67749" x1="16.8231" y1="29.8958" x2="31.897" y2="29.3837" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8595EA"/>
            <stop offset="1" stop-color="#D085EA"/>
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default TwitterIcon;
