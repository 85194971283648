import Planet1Icon from '../assets/svg/Planet1Icon.js';
import Planet2Icon from '../assets/svg/Planet2Icon.js';
import Planet3Icon from '../assets/svg/Planet3Icon.js';
import Planet4Icon from '../assets/svg/Planet4Icon.js';
import pancakeswap from '../assets/images/pancakeswap.png';
import pinksale from '../assets/images/pinksale.png';
import cmc from '../assets/images/cmc.png';
import dextools from '../assets/images/dextools.png';
import TelegramIcon from '../assets/svg/TelegramIcon.js';
import TwitterIcon from '../assets/svg/TwitterIcon.js';

export const spacemap = [
  {
    title: 'PHASE 1',
    image: <Planet1Icon/>,
    style: 'vertical',
    values: [
      'Foundation and Conceptualization',
      'Community Building and Token Distribution',
      'Website creation and launch',
      'Whitepaper release',
      'Contract Creation',
      'KYC and Audit',
      'Pinksale Fairlaunch',
      '1000 Holders',
      'Marketing campaign',
      'Partnerships with influences',
      'Pancakeswap listing',
      'CMC and CG listing',
      'Bitmart listing',
      'MEXC listing',
    ]
  },
  {
    title: 'PHASE 2',
    image: <Planet2Icon/>,
    style: 'horizontal',
    values: [
      '10000 Holders',
      'NFT reveal',
      'NFT Marketplace',
      'Metaverse creation',
      'Games creation',
    ]
  },
  {
    title: 'PHASE 3',
    style: 'square',
    image: <Planet3Icon/>,
    values: [
      '100000 Holders',
      'Major CEX listings',
      'Metaverse release',
      'Games release'
    ]
  },
  {
    title: 'PHASE 4',
    style: 'square',
    image: <Planet4Icon/>,
    values: [
      'AMA with Elon Musk',
      'Space mission with SpaceX',
      'Coming soon',
    ]
  }
];
export const partners = [
  {
    image: pancakeswap,
    url: 'https://pancakeswap.finance/swap'
  },
  {
    image: pinksale,
    url: 'https://www.pinksale.finance/launchpads?chain=BSC'
  },
  {
    image: cmc,
    url: 'https://coinmarketcap.com/'
  },
  {
    image: dextools,
    url: 'https://www.dextools.io/app/en/bnb/pairs'
  },
];
export const howToBuy = [
  {
    title: 'Create Wallet',
    text: 'Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.',
    xs: 4,
  },
  {
    title: 'Get Some Bnb',
    text: 'Have BNB in your wallet to switch to SpaceXPEPE. If you don’t have any BNB, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.',
    xs: 6,
  },
  {
    title: 'Go To PancakeSwap',
    text: 'Connect your wallet to PancakeSwap.finance. Paste the SpaceXPEPE token address into PancakeSwap, select SpaceXPEPE, and confirm. When Metamask prompts you for a wallet signature, sign',
    xs: 6,
    maxWidth: 700,
  },
  {
    title: 'Switch BNB for SpaceXPEPE',
    text: 'Switch BNB for SpaceXPEPE. We have 0 % taxes so you need to buy with 0.5 - 1 slippage, although you may need to use slippage 2 during times of market volatility.',
    xs: 4,
  }
];

export const socialMedias = [
  {
    icon: <TelegramIcon
      onClick={() => window.open('https://t.me/SpaceXPepe', '_blank')}
    />,
  },
  {
    icon: <TwitterIcon
      onClick={() => window.open('https://twitter.com/SpaceX_Pepe', '_blank')}
    />,
  }
];
