import { Box, Grid, Typography } from '@mui/material';
import PartnersSPXPEPE from '../../assets/images/PartnersSPXPEPE.png';

function Partners(props) {
  const { partners } = props;
  return (
    <Box sx={{
      height: { sm: 'auto', md: '100vh' },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      m: 16,
      mt: 24,
    }}>
      <img src={PartnersSPXPEPE} alt={'partners-SPX-PEPE'}
           style={{
             maxWidth: 300
           }}
      />
      <Box sx={{ flexGrow: 1, textAlign: 'center', mt: 4 }}>
        <Typography
          sx={{ fontSize: { xs: '40px', md: '50px' } }}
          fontWeight={800}>
          PARTNERS
        </Typography>
        <Grid container spacing={3}>
          {partners.map((partner) => (
            <Grid item xs sx={{ mt: 4 }}>
              <Box
                onClick={() => window.open(partner.url, '_blank')}
                sx={{
                  cursor: 'pointer',
                  minWidth: 224
                }}
              >
                <img
                  src={partner.image}
                  alt={partner.url}
                  style={{
                    minWidth: 224,
                    maxWidth: 224,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Partners;
