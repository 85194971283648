import { Box, Typography } from '@mui/material';

function About() {
  return (<Box
    sx={{
      height: { sm: 'auto', md: '100vh' },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      m: 2,
    }}>
    <Box sx={{ maxWidth: 700 }}>
      <Typography color={'#8595EA'} sx={{ mb: 2 }} fontWeight={800} variant={'h6'}>
        ABOUT US
      </Typography>
      <Typography fontWeight={800} variant={'h3'} sx={{ mb: 2 }}>
        In the vast expanse of the digital universe
      </Typography>
      <Typography variant={'h6'} color={'#D2D1D2'} sx={{ mb: 2 }}>
        <b style={{ color: 'white' }}>SpaceX PEPE</b> is an innovative and thrilling meme cryptocurrency that pays
        homage to the awe-inspiring world of
        space exploration and the groundbreaking achievements of SpaceX. Created for space enthusiasts, meme lovers,
        and cryptocurrency enthusiasts alike, SPXPepe combines the excitement of space travel with the fun and humor
        of internet memes.
      </Typography>
      <Typography variant={'h6'} color={'#D2D1D2'} sx={{ mb: 2 }}>
        <b style={{ color: 'white' }}>SpaceX PEPE</b> proudly associates itself with SpaceX, the renowned private
        aerospace company led by Elon Musk. The
        coin seeks to establish collaborations and partnerships with SpaceX
      </Typography>
      <Typography variant={'h6'} color={'#D2D1D2'} fontWeight={600}>
        <b style={{ color: 'white' }}>SpaceX PEPE</b> sails through the cosmos, a testament to the limitless
        possibilities that lie within the fusion of
        memes and the vastness of space.
      </Typography>
    </Box>
  </Box>);
}

export default About;
