import { Box, Grid, Typography } from '@mui/material';
import RoadmapCard from '../../components/RoadmapCard/RoadmapCard.js';

function Spacemap(props) {
  const { spacemap } = props;
  const item1 = spacemap[0];
  const item2 = spacemap[1];
  const item3 = spacemap[2];
  const item4 = spacemap[3];
  return (
    <Box
      sx={{
        // height: { sm: 'auto', md: '100vh' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mx: { xs: 0, md: 8 },
        my: 12,
      }}>
      <Box sx={{ m: '16px' }}>
        <Box>
          <Typography sx={{ fontSize: { xs: '40px', md: '120px', mb: 3 } }} fontWeight={800}>
            Space Map
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <RoadmapCard {...item1}/>
            </Grid>
            <Grid item container xs spacing={3} columnSpacing={{ xs: 0, sm: 3 }}>
              <Grid item xs={12}>
                <RoadmapCard {...item2}/>
              </Grid>
              <Grid item xs>
                <RoadmapCard {...item3}/>
              </Grid>
              <Grid item xs>
                <RoadmapCard {...item4}/>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Spacemap;
